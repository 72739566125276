import cookie from 'react-cookies';

import * as actionTypes from './actions';
const isBrowser = typeof window !== 'undefined';
const initialState = {
    isAuthenticated: isBrowser && !! sessionStorage.getItem('token_ethias'),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SIGNIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: action.val,
            };
        case actionTypes.SIGNED_OUT:
            return {
                ...state,
                    isAuthenticated: action.val,
                };
        default:
            return state;
    }
}

import cookie from 'react-cookies';

import * as actionTypes from './actions';
const isBrowser = typeof window !== 'undefined';
const initialState = {
    page: 1,
    maxPages: 4, // Total pages of the journey - neccessary for the Wizard handling
    isLoading: false,
    language: 'nl',
    loggedIn: false,
    changePasswordModal: false,
    direction: 0
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_PAGE:
            return {
                ...state,
                page: action.val,
            };
        case actionTypes.SET_LANGUAGE:
            return {
                ...state,
                language: action.val,
            };
        case actionTypes.SET_DIRECTION:
            return {
                ...state,
                direction: action.val,
            };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.val,
            };
        case actionTypes.LOGGED_IN:
            return {
                ...state,
                loggedIn: action.val,
            };
        case actionTypes.SET_PASSWORD_MODAL:
            return {
                ...state,
                changePasswordModal: action.val,
            };
        default:
            return state;
    }
}

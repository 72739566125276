/* eslint-disable no-console */
import React, { ReactNode, useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useNavigate, Navigate, Outlet } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

// import { AuthContext } from './authContext';
import RootState from '@src/interfaces/RootState';
import { useTranslation } from 'react-i18next';
import { REFRESH_TOKEN } from '@src/redux/auth/actions';
import { SET_LOADING } from '@src/redux/ui/actions';
import AuthService from '@src/api/services/authService';

interface PrivateRouteProps {
    children: ReactNode;
    exact?: boolean;
    props?: any;
    path?: string;
    dummy?: any;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute = ({ children, dummy, ...rest }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    // const {
    //     authState: { isAuthenticated },
    // } = React.useContext<any>(AuthContext);
    const isAuthenticated = useSelector((state: RootState) => state?.auth?.isAuthenticated);
    // const { authDispatch } = useContext<any>(AuthContext);
    const navigate = useNavigate();

    /* FUNCTIONS */
    const logOut = () => {
        console.log('logOut...');
        sessionStorage.clear();
        // cookie.remove('token_ethias');
        dispatchStore({ type: 'SIGNED_OUT', val: false });

        // authDispatch({ type: 'SIGNED_OUT' });
        // navigate('/');
    };

    // const refreshToken = () => {
    //     dispatchStore({ type: REFRESH_TOKEN });
    // };

    const handleLogout = () => {
        console.log('handleLogout');
        if (isAuthenticated) {
            logOut();
        }
    };

    const handleOnIdle = () => {
        if (isAuthenticated) {
            refreshToken();
            reset();
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleOnActive = (event) => {};

    const { reset } = useIdleTimer({
        timeout: 1000 * 600 * 1,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        events: [dummy],
        debounce: 500,
    });

    const { getRemainingTime } = useIdleTimer({
        timeout: 1000 * 600 * 1,
        onIdle: handleLogout,
        debounce: 500,
    });

    /* USEEFFECTS */
    useEffect(() => {
        if (!isAuthenticated) {
            console.log('Logged out');
            navigate('/');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            refreshToken();
        } else {
            logOut();
        }
    }, []);
    useEffect(() => {
        dispatchStore({ type: REFRESH_TOKEN });
    }, []);
    const refreshToken = () => {
        dispatchStore({ type: SET_LOADING, val: true });
        // dispatchStore({ type: 'PERFORM_LOGIN' });

        AuthService.tokenRefresh()
            .then((response) => {
                // cookie.save('token_ethias', `${response.headers.authorization}`, { path: '/' /*secure:true*/ });
                sessionStorage.setItem('token_ethias', `${response.headers.authorization}`);
                dispatchStore({ type: SET_LOADING, val: false });
                // return data;
            })
            .catch((err) => {
                sessionStorage.clear();
                cookie.remove('token_ethias');
                console.log('ERROR', err.response);
            });
    };

    return (
        <>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</>
        // <Route
        //     {...rest}
        //     render={({ location }) =>
        //         isAuthenticated ? (
        //             children
        //         ) : (
        //             <Navigate
        //                 to={{
        //                     pathname: '/',
        //                     // state: { from: location },
        //                 }}
        //             />
        //         )
        //     }
        // />
    );
};

export default PrivateRoute;

import axiosInstance from '../http/axiosSetup';
import { LoginPayload } from '../interfaces/Login';
import cookie from 'react-cookies';

export default class AuthService {
    static performLogin = (body: LoginPayload) =>
        axiosInstance.post('authentications/microsite-login', body).then((res) => {
            return res;
        });
    static verifyCode = (body: Record<string, any>) =>
        axiosInstance.post<any>('authentications/microsite-verification', body).then((response) => {
            sessionStorage.setItem('token_ethias', `${response.headers.authorization}`);
            return response.data;
        });
    static urlDecode = (body: Record<string, any>) =>
        axiosInstance.post<any>('microsites/ethias/url-decode', body).then((response) => {
            return response.data;
        });

    static tokenRefresh = () => {
        return axiosInstance.get('refresh/token').then((response) => {
            sessionStorage.setItem('token_ethias', `${response.headers.authorization}`);
            return response;
        });
    };
}
